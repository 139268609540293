import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "handle", "tab", "tabsContainer" ]

  connect() {
    if (!this.data.has('state')) {
      this.data.set('state', this.errorState() || this.firstState())
    }
    this.markErrorHandles()
    this.update()
    this.scrollToError()
  }

  firstState() {
    return this.tabTargets[0].dataset.name
  }

  errorState() {
    let tab = this.errorTab()
    if (tab != undefined) {
      return tab.dataset.name
    }
  }

  errorTab() {
    return this.errorTabs()[0]
  }
  errorTabs() {
    return this.tabTargets.filter(tab => tab.querySelector('.form-error') != null)
  }
  errorHandles() {
    let self = this
    return this.errorTabs().map(tab => self.handle(tab.dataset.name))
  }

  scrollToError() {
    let tab = this.tab(this.data.get('state'))
    let error = tab.querySelector('.is-invalid-input')
    if (error != null) {
      this.tabsContainerTarget.scrollTop = error.offsetTop - this.tabsContainerTarget.offsetTop
    }
  }

  setState(event) {
    this.data.set('state', event.target.dataset.name)
    this.update()
  }

  update() {
    this.closeAll()
    this.open(this.data.get('state'))
  }

  tab(name) {
    return this.tabTargets.find(tab => tab.dataset.name == name)
  }
  handle(name) {
    return this.handleTargets.find(handle => handle.dataset.name == name)
  }

  markErrorHandles() {
    let self = this
    this.handleTargets.forEach(handle => self.hide(handle.querySelector('[data-product-tabs-icon]')))
    this.errorHandles().forEach(handle => self.show_inline(handle.querySelector('[data-product-tabs-icon]')))
  }

  closeAll() {
    let self = this
    this.handleTargets.forEach(handle => self.close(handle.dataset.name))
  }

  close(name) {
    this.hide(this.tab(name))
    this.handle(name).parentNode.classList.remove('c-tabs__tab--active')
  }
  open(name) {
    this.show(this.tab(name))
    this.handle(name).parentNode.classList.add('c-tabs__tab--active')
  }

  hide(el) {
    el.style.display = 'none'
  }
  show(el) {
    el.style.display = 'block'
  }
  show_inline(el) {
    el.style.display = 'inline-block'
  }
}
