import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'add', 'warning', 'item'
  ]

  connect() {
    this.update()
  }

  update() {
    if (this.maximum() === null) { return }

    this.enforceMaximum(this.maximum())
  }
  beforeAdd() {
    if (this.maximum() === null) { return }

    this.enforceMaximum(this.maximum() - 1)
  }
  beforeRemove() {
    if (this.maximum() === null) { return }

    this.enforceMaximum(this.maximum() + 1)
  }

  enforceMaximum(max) {
    if (this.itemTargets.length < max) {
      this.add()
    } else {
      this.warn()
    }
  }

  maximum() {
    if (!this.data.has('maximum')) { return }

    return parseInt(this.data.get('maximum'))
  }

  add() {
    this.addTarget.style.display = 'inline-block'
    this.warningTarget.style.display = 'none'
  }
  warn() {
    this.addTarget.style.display = 'none'
    this.warningTarget.style.display = 'inline-block'
  }
}
