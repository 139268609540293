// Entry point for the build script in your package.json

import './controllers'
import jQuery from 'jquery'
import Foundation from 'foundation-sites'
import "@hotwired/turbo-rails"

document.addEventListener('turbo:load', () => {
  jQuery(document).foundation()
})
