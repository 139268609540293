import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="same-address"
export default class extends Controller {
  static targets = ['shippingField', 'toggleField']

  connect() {
    this.toggleFieldTarget.addEventListener('change', (_e) => this.toggle())
    this.toggle()
  }

  toggle() {
    this.shippingFieldTargets.forEach((field) => {
      if(this.toggleFieldTarget.checked) {
        field.value = ''
      }
      field.disabled = this.toggleFieldTarget.checked
    })
  }
}
