import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="duplicate-name"
export default class extends Controller {
  static targets = ['from', 'to']
  connect() {
    this.fromTargets.forEach((elem) => elem.addEventListener('keyup', (e) => this.duplicateToTarget()))
    this.fromTargets.forEach((elem) => elem.addEventListener('change', (e) => this.duplicateToTarget()))
  }

  duplicateToTarget() {
    this.toTarget.value = this.fromTargets.map((elem) => elem.value)
      .filter((value) => value !== '' && value !== undefined)
      .join(' ')
  }
}
