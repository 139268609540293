import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'select', 'input', 'inputbox', 'disabledbox' ]

  connect() {
    this.updateSelect()
  }

  updateSelect() {
    if (this.selectTarget.value == 'bo') {
      this.switchToInput()
    } else {
      this.switchToDisabled()
      this.clearInput()
    }
  }

  clearInput() {
    this.inputTarget.value = ''
  }

  switchToInput() {
    this.inputboxTarget.style.display = 'inline-block'
    this.disabledboxTarget.style.display = 'none'
  }
  
  switchToDisabled() {
    this.inputboxTarget.style.display = 'none'
    this.disabledboxTarget.style.display = 'inline-block'
  }
}
