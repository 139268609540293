import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'quantity', 'unitPrice', 'totalPrice' ]

  connect() {
    this.update()
  }

  update() {
    let quantity = this.sanitize(this.quantityTarget.value)
    let unit_price = this.sanitize(this.unitPriceTarget.value)
    
    this.totalPriceTarget.value = '€ ' + (quantity * unit_price).toString()
  }

  sanitize(number) {
    var result = parseFloat(number.replace(',', '.').replace('€ ', ''))
    if (isNaN(result)) { result = 0 }
    return result
  }
}
