import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['check', 'input']

  connect() {
    this.value = this.inputTarget.value
    this.set();
  }

  set(event) {
    if (this.checkTarget.checked || this.value == 'pallet') {
      this.inputTarget.value = 'pallet';
      this.inputTarget.setAttribute('readonly', true);

      if (event) { 
        this.inputTarget.dispatchEvent(new Event('change', {bubbles: true}));
      }  
    } else {
      this.inputTarget.value = this.value;
      this.inputTarget.removeAttribute('readonly');
    }
  }
}
