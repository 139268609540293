import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'all', 'box'
  ]

  connect() {
    this.deriveAll()
  }

  updateAll() {
    this.boxTargets.forEach(e => { e.checked = this.allTarget.checked })
  }

  updateSingle() {
    this.deriveAll()
  }

  deriveAll() {
    var all = true
    this.boxTargets.forEach(e => { all = all && e.checked })

    this.allTarget.checked = all
  }
}
