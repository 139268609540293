// without groups:
// <div data-controller="checkboxes">
//   <label><input type="checkbox" data-target="checkboxes.all" data-action="checkboxes#toggle" /> Select all</label>
//   <br>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" /> Option 1</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" /> Option 2</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" /> Option 3</label>
// </div>
//
// with groups:
// <div data-controller="checkboxes">
//   <label><input type="checkbox" data-target="checkboxes.all" data-action="checkboxes#toggle" data-checkboxes-group="A" /> Select all group A</label>
//   <label><input type="checkbox" data-target="checkboxes.all" data-action="checkboxes#toggle" data-checkboxes-group="B" /> Select all group B</label>
//   <br>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="A" /> Option 1a</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="A" /> Option 2a</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="A" /> Option 3a</label>
//   <br>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="B" /> Option 1b</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="B" /> Option 2b</label>
//   <label><input type="checkbox" data-target="checkboxes.checkbox" data-action="checkboxes#refresh" data-checkboxes-group="B" /> Option 3b</label>
// </div>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['all', 'checkbox']

  initialize () {
  }

  connect () {
    this.refresh()
  }

  disconnect () {
  }

  toggle (e) {
    e.preventDefault()
    let group = e.currentTarget.dataset.checkboxesGroup;

    if ( group !== undefined ) {
      this.checkboxTargets.forEach(checkbox => {
        if ( checkbox.matches(`[data-checkboxes-group="${group}"]`) ) {
          checkbox.checked = e.target.checked
        }
      });
    } else {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = e.target.checked
      })
    }

  }

  refresh (e) {
    this.allTargets.forEach(checkbox => {
      let group = checkbox.dataset.checkboxesGroup;

      if ( group !== undefined ) {
        const checkboxesCount = this.element.querySelectorAll(`[data-checkboxes-target="checkbox"][data-checkboxes-group="${group}"]`).length
        const checkboxesCheckedCount = this.element.querySelectorAll(`[data-checkboxes-target="checkbox"][data-checkboxes-group="${group}"]:checked`).length

        this.element.querySelector(`[data-checkboxes-target="all"][data-checkboxes-group="${group}"]`).checked = checkboxesCheckedCount > 0
        this.element.querySelector(`[data-checkboxes-target="all"][data-checkboxes-group="${group}"]`).indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
      } else {
        const checkboxesCount = this.checkboxTargets.length
        const checkboxesCheckedCount = this.checked.length

        this.allTarget.checked = checkboxesCheckedCount > 0
        this.allTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
      }
    });
  }

  get checked () {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked () {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}
