import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="insert-at-caret"
export default class extends Controller {
  static targets = ['textArea', 'button']

  connect() {
  }

  buttonTargetConnected(button) {
    button.addEventListener('click', (e) => { this.insertAtCaret(button.dataset.value); e.preventDefault() } )
  }

  insertAtCaret(value) {
    if (this.textAreaTarget.selectionStart || this.textAreaTarget.selectionStart === 0) {
      let startPos = this.textAreaTarget.selectionStart;
      let endPos = this.textAreaTarget.selectionEnd;
      this.textAreaTarget.value = this.textAreaTarget.value.substring(0, startPos) +
        this.textAreaTarget.value.substring(endPos, this.textAreaTarget.value.length) + value;
      this.textAreaTarget.selectionStart = startPos + value.length;
      this.textAreaTarget.selectionEnd = startPos + value.length;
    } else {
      this.textAreaTarget.value += value;
    }
  }
}
