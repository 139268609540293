import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'select', 'input', 'hiddenInput'  ]

  connect() {
    this.updateSelect()
  }

  updateInput() {
    let value = parseInt(this.inputTarget.value)
    if (value !== undefined && value !== null && value <= 10) {
      this.selectTarget.value = value || 1
      this.switchToSelect()
    } else {
      this.switchToInput()
    }

    this.updateValue()
  }

  updateSelect() {
    if (this.selectTarget.value == 'more') {
      let value = parseInt(this.inputTarget.value)
      if (value !== undefined && value !== null && value <= 10) {
        this.inputTarget.value = null
      }
      this.switchToInput()
    } else {
      this.switchToSelect()
    }

    this.updateValue()
  }

  updateValue() {
    this.hiddenInputTarget.value = this.data.get('value')
  }

  switchToInput() {
    this.inputTarget.style.display = 'inline-block'
    this.selectTarget.style.display = 'none'
    this.data.set('value', this.inputTarget.value)
  }

  switchToSelect() {
    this.inputTarget.style.display = 'none'
    this.selectTarget.style.display = 'inline-block'
    this.data.set('value', this.selectTarget.value)
  }
}
